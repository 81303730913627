if (window.location.port.length > 3) {
  console.log("debug");
  var btn = document.createElement("button");
  btn.style.position = "fixed";
  btn.style.top = 0;
  btn.style.right = 0;
  btn.style.zIndex = 9999;
  btn.innerHTML = "DEBUG: " + localStorage.getItem("debug");
  btn.onclick = function () {
    if (localStorage.getItem("debug") === "1") {
      localStorage.setItem("debug", 0);
    } else {
      localStorage.setItem("debug", 1);
    }

    window.location.reload();
  };
  document.body.appendChild(btn);

  var dl = document.createElement("button");
  dl.style.position = "fixed";
  dl.style.top = "20px";
  dl.style.right = 0;
  dl.style.zIndex = 9999;
  dl.innerHTML = "SAVE";
  dl.onclick = function () {
    ["answers", "steps", "stepDefinitions", "tree"].forEach((e) => {
      localStorage.setItem(e, JSON.stringify(window[e]));
    });
  };
  document.body.appendChild(dl);

  var dev = document.createElement("button");
  dev.style.position = "fixed";
  dev.style.top = "40px";
  dev.style.right = 0;
  dev.style.zIndex = 9999;
  dev.innerHTML = "devenv: " + localStorage.getItem("devenv");
  dev.onclick = function () {
    if (localStorage.getItem("devenv") === "1") {
      localStorage.setItem("devenv", 0);
    } else {
      localStorage.setItem("devenv", 1);
    }

    window.location.reload();
  };
  document.body.appendChild(dev);
}
